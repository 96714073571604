/* * {
  margin: 0;
  padding: 0;
} */

.App {
  min-height: 100vh;
}

/* .container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
} */
